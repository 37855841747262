import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import RESTAPIConfig from "@/config/RESTAPIConfig";
import {AccessTokenStorageService} from "@/features/Auth/CurrentUser/Services/AccessTokenStorageService";

const restApiConfig = new RESTAPIConfig();
export const userLangHeaderName = "User-Lang";

export function getAccessToken(state: unknown) {
    //@ts-ignore
    const tokenFromState = state.currentUser.token;
    if (tokenFromState !== "") {
        return tokenFromState;
    }

    const tokenStorageService = new AccessTokenStorageService();
    return tokenStorageService.getTokenFromStorage();
}

export const ApiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: restApiConfig.baseUrl + restApiConfig.apiPrefix,
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        prepareHeaders: (headers, api) => {
            //@ts-ignore
            const userLang = api.getState().lang.userLang as string;
            headers.append(userLangHeaderName, userLang);

            const accessToken = getAccessToken(api.getState());
            headers.append("Authorization", `Bearer ${accessToken}`);

            return headers;
        },
    }),
    tagTypes: [
        "FetchedData",
        "ContactFormRoles", "CurrentUser", "OrderedTestTypes", "MyOrders",
        "AllOrders", "UsersInCP", "ApplicationsForChangePersonalData",
        "KnowledgeBaseSearchResults", "Appeal", "AppealType", "User", "Analysis", "KnowledgeBaseArticle",
        "FamilyLink", "Sample", "Sampling", "LegalStatus", "ResearchSigns", "OrderTerms",
        "PartnerType", "PregnancyPeculiarity", "ParticipantGenders", "ParticipantDocumentTypes",
    ],
    endpoints: builder => ({})
});
