import { setGlobalError } from '@/components/ShowGlobalError/Slice/ShowGlobalErrorSlice';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      let message = "";

      const currentUserRegex = /get-current-user/gmi;

      if (action.meta.arg.endpointName === "downloadFile" && action.payload.result === "failed") {
        message += action.payload.status + " " + action.payload.errors.toString() + "<br />";
        message += action.meta.arg.originalArgs.url;
        return next(action);
      }

      if (action.payload.status === 401 && currentUserRegex.test(action.meta.baseQueryMeta.request.url)) {
        return next(action);
      }

      if (action.meta.arg.endpointName === "allBarcodesPrinted") {
        message += action.payload.status + " Error: " + action.payload.error + "<br />";;
        message += "Method: " + action.meta.baseQueryMeta.request.method + "<br />";
        message += "URL: " + action.meta.baseQueryMeta.request.url;
        
        if ("data" in action.payload && "errors" in action.payload.data) {
          message += "Errors from server: " + action.payload.data.errors.toString();
        }
        
        return next(action);
      }

      if (action.type === "api/executeQuery/rejected") {
        message += action.payload.status + " " + action.payload.error + "<br />";
        message += "Method: " + action.meta.baseQueryMeta.request.method + "<br />";
        message += "URL: " + action.meta.baseQueryMeta.request.url;
      }

      api.dispatch(setGlobalError(message));
    }

    return next(action);
  }