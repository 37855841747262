import { Alert } from "@mui/material";
import { ReactNode, createContext, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ErrorBoundary } from "react-error-boundary";
import { userLangSelector } from "@/features/LangSwitcher/Slice/LangSlice";
import { useSelector } from "react-redux";

const ErrorContext = createContext<{ errorId: string }>({ errorId: '' });
const useErrorContext = () => useContext(ErrorContext);

function ErrorFallback() {

    const { t } = useTranslation();
    const { errorId } = useErrorContext();

    return (
        <Alert severity="error" sx={{mt: 1, mb: 1,}}>
            <div>
                {t("Something went wrong. Error code:")} {errorId}
            </div>
        </Alert>
    );
}

const logError = (error: Error, info: { componentStack: string }) => {
    console.log('error is', error, 'info is', info);
};

function ErrorsCatcher({ children, errorId }: { children: ReactNode | ReactNode[], errorId: string, }) {

    const lang = useSelector(userLangSelector);

    return (
        <ErrorContext.Provider value={{ errorId }}>
            <ErrorBoundary
                onError={logError}
                fallback={<ErrorFallback />}
            >
                {children}
            </ErrorBoundary>
        </ErrorContext.Provider>
    );
}

export default ErrorsCatcher;