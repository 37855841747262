import { createContext, ReactNode, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

const TranslationFuncContext = createContext<TFunction<"translation", undefined> | undefined>(undefined);
export const useT = () => {
    const context = useContext(TranslationFuncContext);
    if ( ! context) {
        throw new Error('The useT hook must be used within the TranslationProvider.');
    }
    return context;
};

function TranslationProvider({ children, }: { children: ReactNode | ReactNode[], }) {
    const { t } = useTranslation();
    const translation = useCallback(t, []);

    return (
        <TranslationFuncContext.Provider value={translation}>
            {children}
        </TranslationFuncContext.Provider>
    )
}

export default TranslationProvider;