export default class RESTAPIConfig {

    private get protocol() {
        // return window.location.protocol;
        return "https";
    }

    private get host() {
        if (window.location.hostname === "lk.inlab-genetics.ru") {
            return "b2b-inlab.com";
        }
        return "test.b2b-inlab.com";

        // return "lk.ralzo.ru";
        // return window.location.hostname;
    }

    public get apiPrefix() {
        return "/api";
    }

    public get baseUrl() {
        // return `a0460902.xsph.ru`;
        return `${this.protocol}://${this.host}`;
    }

    public controlPanel() {
        return `/control-panel`;
    }

    // refactor
    public saveNewOrderForDNATest() {
        return `/create-new-order/save`;
    }

    // refactor
    public updateOrderForDNATest() {
        return `/order/update`;
    }

    // done
    public contactFormRoles() {
        return `/partners/contact-form-roles`;
    }

    // *
    public saveNewPartner() {
        return "/partners/save-new";
    }

    public updatePartner() {
        return "/control-panel/partners/update";
    }

    public getPartnersShortInfoForAll() {
        return "/control-panel/partners/get-short-info-for-all";
    }

    public getManagersForPartners() {
        return "/control-panel/users/get-all?selectedRoles=4";
    }

    public getDeleteUser({id}: {id: number}) {
        return `/control-panel/users/delete-user/${id}`;
    }

    public getApplicationsForChangePersonalData() {
        return "/control-panel/applications-for-change-personal-data/get-applications";
    }

    public getApplicationForChangePersonalDataById({applicationId}: {applicationId: number}) {
        return `/control-panel/applications-for-change-personal-data/get-application-by-id/${applicationId}`;
    }

    public getApplicationForChangePersonalMakeDecision() {
        return `/control-panel/applications-for-change-personal-data/make-decision`;
    }
    
    public applicationForChangePersonalCloseDecision() {
        return `/control-panel/applications-for-change-personal-data/close-decision`;
    }

    public loginUser() {
        return "/auth/login";
    }

    public currentUser() {
        return "/auth/get-current-user";
    }

    // thunk
    public logout() {
        return "/auth/logout";
    }

    public myOrders() {
        return "/my-orders";
    }

    public orderedTestTypes() {
        return "/my-orders/get-ordered-test-types";
    }

    // thunk
    public getDNAOrderData({orderId}: {orderId: number}) {
        return `/my-orders/order/${orderId}/get-data`;
    }

    public softDeleteOrder({orderId}: {orderId: number}) {
        return `/my-orders/order/${orderId}/soft-delete`;
    }

    public getBarcodesForOrder({orderId}: {orderId: number}) {
        return `/my-orders/order/${orderId}/barcode`;
    }

    public allBarcodesPrintedForOrder({orderId}: {orderId: number}) {
        return `/my-orders/order/${orderId}/barcode/all-printed`;
    }

    public orderSheetPrinted({orderId}: {orderId: number}) {
        return `/my-orders/order/${orderId}/sheet-printed`;
    }

    /* TODO убрать дублирование */
    public getMyOrders() {
        return "/my-orders";
    }

    public getParticipantGenders() {
        return "/my-orders/genders";
    }

    public getParticipantDocumentTypes() {
        return "/my-orders/document_types";
    }

    public getAllTestTypes() {
        return "/get-all-test-types";
    }
    
    public createNewTest() {
        return "/create-new-test";
    }

    public updateTest() {
        return "/update-test";
    }

    public toggleTestInTrash() {
        return "/toggle-test-in-trash";
    }

    public testsInTrash() {
        return "/get-test-in-trash-count";
    }

    public deleteTestsInTrash() {
        return "/delete-tests-in-trash";
    }

    public importTestsFromBitrix() {
        return "/get-tests-from-bitrix";
    }

    // thunk
    public getDNAOrderUploadFilesInControlPanel({orderId}: {orderId: string}) {
        return `${this.controlPanel()}/all-orders/order/${orderId}/upload-files`;
    }

    // thunk
    public getDownloadFileFromLabForOrder({fileId}: {fileId: string}) {
        return `/download-lab-file-for-order/${fileId}`;
    }

    // thunk
    public getPrintFileFromLabForOrder({fileId}: {fileId: string}) {
        return `/print-lab-file-for-order/${fileId}`;
    }

    // thunk
    public getDeleteFileFromLabForOrder({fileId}: {fileId: string}) {
        return `/delete-lab-file-for-order/${fileId}`;
    }

    // thunk
    public getUploadFileForKnowledgeBaseArticle() {
        return `${this.controlPanel()}/knowledge-base/create-article/upload-file`;
    }

    // thunk
    public getUploadFileForKnowledgeBaseEditArticle() {
        return `${this.controlPanel()}/knowledge-base/edit-article/upload-file`;
    }

    // thunk
    public getSaveKnowledgeBaseArticle() {
        return `${this.controlPanel()}/knowledge-base/create-article/save`;
    }

    // thunk
    public getSaveKnowledgeBaseEditArticle() {
        return `${this.controlPanel()}/knowledge-base/edit-article/save`;
    }

    // thunk
    //TODO дублирование
    public getKnowledgeBaseGetArticle({articleId}: {articleId: string}) {
        return `/knowledge-base/article/${articleId}/get-data`;
    }

    public getKnowledgeBaseSearchNew() {
        return `/knowledge-base/search`;
    }

    // thunk
    public getArticlesOfCategoryInKnowledgeBase({categoryId}: {categoryId: number}) {
        return `${this.controlPanel()}/knowledge-base/get-articles-of-category/${categoryId}`;
    }

    // thunk
    public getArticlesInTrashCountInKnowledgeBase() {
        return `${this.controlPanel()}/knowledge-base/get-articles-in-trash-count`;
    }

    // thunk
    public getDeleteArticlesInTrashInKnowledgeBase() {
        return `${this.controlPanel()}/knowledge-base/delete-articles-in-trash`;
    }

    // thunk
    public getKnowledgeBaseArticleData({articleId}: {articleId: string}) {
        return `/knowledge-base/article/${articleId}/get-data`;
    }

    // thunk
    public getKnowledgeBaseArticleChangeInTrash() {
        return `${this.controlPanel()}/knowledge-base/change-article-in-trash`;
    }

    // thunk
    public getKnowledgeBaseCategoriesTree() {
        return `${this.controlPanel()}/knowledge-base/get-categories-tree`;
    }

    // thunk
    public getKnowledgeBaseSaveNewCategory() {
        return `${this.controlPanel()}/knowledge-base/save-new-category`;
    }

    // thunk
    public getKnowledgeBaseEditCategory() {
        return `${this.controlPanel()}/knowledge-base/edit-category`;
    }

    // thunk
    public getKnowledgeBaseToggleCategoryInTrash() {
        return `${this.controlPanel()}/knowledge-base/toggle-category-in-trash`;
    }

    // thunk
    public getKnowledgeBaseGetCategoriesInTrash() {
        return `${this.controlPanel()}/knowledge-base/get-categories-in-trash-count`;
    }

    // thunk
    public getDeleteCategoriesAndArticlesInTrashInKnowledgeBase() {
        return `${this.controlPanel()}/knowledge-base/delete-categories-and-articles-in-trash`;
    }

    public getUsersRoles() {
        return `${this.controlPanel()}/users/get-all-user-roles`;
    }

    public getSeniorManagers() {
        return `${this.controlPanel()}/users/get-all?selectedRoles=5`;
    }

    public getSaveUser() {
        return `${this.controlPanel()}/users/save-new-user`;
    }

    public getUpdateUser({id}: {id: number}) {
        return `${this.controlPanel()}/users/update-user/${id}`;
    }

    public getUsers() {
        return `${this.controlPanel()}/users/get-all`;
    }

    public inControlPanelGetUserById({userId}: {userId: number}) {
        return `${this.controlPanel()}/users/get-by-id/${userId}`;
    }

    public getPartnerInfoById({id}: {id: number}) {
        return `/partners/get/${id}`;
    }

    public getManagerByPartnerId({id}: {id: number}) {
        return `/partners/get-manager/${id}`;
    }

    public confirmPartnerData() {
        return "/auth/access-personal-data";
    }

    public appeals() {
        return "/appeals";
    }

    public appealsList() {
        return "/appeals/my";
    }

    public appealsTypes() {
        return "/appeals/types";
    }

    public replyToAppeal({id}: {id: number}) {
        return `/appeals/${id}/reply`;
    }

    public callCourier() {
        return "/courier/call";
    }

    public flmngr() {
        return "/flmngr";
    }

    public storageImages() {
        return "/storage/images";
    }

    public getAllFamilyLinks() {
        return "/family-links/get-all";
    }
    
    public getAllSamples() {
        return "/samples/get-all";
    }
    
    public getAllSamplings() {
        return "/samplings/get-all";
    }

    public documentsCreate() {
        return "/documents/create";
    }

    public documentDelete() {
        return "/documents/delete";
    }

    public orderLegalStatus() {
        return "/list/order-legal-status";
    }

    public researchSigns() {
        return "/list/research-signs";
    }

    public orderTerms() {
        return "/list/order-terms";
    }

    public partnerTypes() {
        return "/list/partner-type";
    }

    public pregnancyPeculiarities() {
        return "/list/pregnancy";
    }

    public createParticipantForOrder() {
        return "/order/participant/create";
    }

    public deleteParticipantFromOrder() {
        return "/order/participant/delete";
    }

    public updateParticipantForOrder() {
        return "/order/participant/update";
    }
}