import { ReactNode } from "react";
import styles from "./styles/PrintOrderForm.module.scss";

function Section({children, header}: {children: ReactNode | ReactNode[], header: string}) {
    return (
        <fieldset className={styles.section}>
            <legend className={styles.sectionHeader}>{header}</legend>
            {children}
        </fieldset>
    );
}

export default Section;