import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    login: "",
    loginError: "",
    loginValid: false,

    partnerType: "",
    partnerTypeError: "",
    partnerTypeValid: true,

    manager: "",
    managerError: "",
    managerValid: false,

    companyFull: "",
    companyFullError: "",
    companyFullValid: false,

    companyShort: "",
    companyShortError: "",
    companyShortValid: false,

    legalAddress: "",
    legalAddressError: "",
    legalAddressValid: false,

    companyPhone: "",
    companyPhoneError: "",
    companyPhoneValid: true,

    INN: "",
    INNError: "",
    INNValid: false,

    KPP: "",
    KPPError: "",
    KPPValid: false,

    OGRN: "",
    OGRNError: "",
    OGRNValid: false,

    nameOfBank: "",
    nameOfBankError: "",
    nameOfBankValid: false,

    checkingAccount: "",
    checkingAccountError: "",
    checkingAccountValid: false,

    correspondentAccount: "",
    correspondentAccountError: "",
    correspondentAccountValid: false,

    BIK: "",
    BIKError: "",
    BIKValid: false,
};

export const RegisterNewPartnerFormSlice = createSlice({
    name: "RegisterNewPartnerForm",
    initialState,
    reducers: {
        setValue: (state, action) => {
            const value = action.payload.value;
            const name = action.payload.name;
            //@ts-ignore
            state[name] = value;
        },
        setToDefault: (state, action) => {
            state.login = "";
            state.loginError = "";
            state.loginValid = false;

            // state.partnerType = "";
            // state.partnerTypeError = "";
            // state.partnerTypeValid = false;

            // state.manager = "";
            // state.managerError = "";
            // state.managerValid = false;

            state.companyFull = "";
            state.companyFullError = "";
            state.companyFullValid = false;

            state.companyShort = "";
            state.companyShortError = "";
            state.companyShortValid = false;

            state.legalAddress = "";
            state.legalAddressError = "";
            state.legalAddressValid = false;

            state.companyPhone = "";
            state.companyPhoneError = "";
            state.companyPhoneValid = true;

            state.INN = "";
            state.INNError = "";
            state.INNValid = false;

            state.KPP = "";
            state.KPPError = "";
            state.KPPValid = false;

            state.OGRN = "";
            state.OGRNError = "";
            state.OGRNValid = false;

            state.nameOfBank = "";
            state.nameOfBankError = "";
            state.nameOfBankValid = false;

            state.checkingAccount = "";
            state.checkingAccountError = "";
            state.checkingAccountValid = false;

            state.correspondentAccount = "";
            state.correspondentAccountError = "";
            state.correspondentAccountValid = false;

            state.BIK = "";
            state.BIKError = "";
            state.BIKValid = false;
        }
    }
});

export const {
    setValue,
    setToDefault,
} = RegisterNewPartnerFormSlice.actions;

//@ts-ignore
export const getSelectorFor = name => state => state.registerNewPartnerForm[name];

//@ts-ignore
export const registerNewPartnerFormIsCorrectSelector = state => {
    const noErrors = state.registerNewPartnerForm.loginValid &&
           state.registerNewPartnerForm.partnerTypeValid &&
           state.registerNewPartnerForm.managerValid &&
           state.registerNewPartnerForm.companyFullValid &&
           state.registerNewPartnerForm.companyShortValid &&
           state.registerNewPartnerForm.legalAddressValid &&
           state.registerNewPartnerForm.companyPhone &&
           state.registerNewPartnerForm.INNValid &&
           state.registerNewPartnerForm.KPPValid &&
           state.registerNewPartnerForm.OGRNValid &&
           state.registerNewPartnerForm.nameOfBankValid &&
           state.registerNewPartnerForm.checkingAccountValid &&
           state.registerNewPartnerForm.correspondentAccountValid &&
           state.registerNewPartnerForm.BIKValid;

    return noErrors;
}

export const RegisterNewPartnerFormReducer = RegisterNewPartnerFormSlice.reducer;