import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            //A
            "All Orders": "All Orders",
            "An error occurred on the server while trying to log out. Reload the page and try again.": "An error has occurred on the server while trying to log out. Reload the page and try again.",
            "Another test": "Another test",
            "Articles": "Articles",
            "Articles in trash can": "Deleted articles",
            "Add a category": "Add a category",
            "Adding a category": "Adding a category",
            "Add": "Add",
            "Articles in the current category and subcategories": "Current category and subcategory articles",
            "Article in the trash can": "Deleted article",
            "Author": "Author",
            "Available extensions": "Available extensions",
            "Adding of a physical address": "Adding of a physical address",
            "Adding a contact": "Adding a contact",
            "An error occurred while saving the partner.": "An error has occurred while saving the partner info.",
            "About the partner": "About the partner",
            "Additional information": "Additional information",
            "Additional services": "Additional services",
            "Act": "Act",
            "Add a document": "Add a document",
            "Added contacts": "Added contacts",
            "Add a user": "Add a user",
            "Are you sure you want to delete the user?": "Are you sure you want to delete the user?",
            "Address": "Address",
            "Added addresses": "Added addresses",
            "An error occurred while updating the partner.": "An error has occurred while updating the partner info.",
            "Add test participant": "Add test participant",
            "Autosomal markers": "Autosomal markers",
            "Are you sure you want to remove the member": "Are you sure you want to remove the member",
            "Additional participant": "Additional participant",
            "Abortive material": "Abortive material",
            "Application date": "Application date",
            "Application for change of personal data": "Application to change personal data",
            "Access is denied": "Access denied",
            "Attach a file": "Attach a file",
            "Administrator": "Administrator",
            "Accountant": "Accountant",
            "An error occured during the request.": "An error occured during the request.",
            "Add an address": "Add an address",
            "Add a contact": "Add a contact",
            "Account info": "Account info",
            "Appeal": "Appeal",
            "Add a test": "Add a test",
            "Alias": "Alias",
            "Are you sure": "Are you sure",
            "A file isn't chosen": "A file isn't chosen",
            "Attributes are loading": "Attributes are loading",
            "A partner is required": "A partner is required",
            "Agreed date of recording": "Agreed date of recording",
            "Appeal types are loading": "Appeal types are loading",

            //B
            /*БИК*/
            "Bank identification code": "Bank Identifier Code",
            "Back": "Back",
            "By the tribunal's decision": "By court order",
            "Bones": "Bones",
            "Blood plasma": "Blood plasma",
            "Brought by the client": "Brought by the client",
            "By participant on the place": "By participant on the place",
            "BIC must be a number.": "BIC must be a number.",
            "BIC consists of 9 digits.": "BIC consists of 9 digits.",
            "BIC is required to be filled out.": "BIC is required to be filled out.",
            "Bill": "Bill",

            //C
            "Control panel": "Control panel",
            "Contacts of your personal manager": "Your personal manager contacts",
            "Close": "Close",
            "Clear": "Clear",
            "Create an article": "Create an article",
            "Categories": "Categories",
            "Clear all articles in the trash": "Empty trash",
            "Cancel": "Cancel",
            "Categories in trash": "Deleted categories",
            "Clear categories and articles from the trash": "Empty trash",
            "Categories and articles have been cleared from the trash": "Trash has been cleared",
            "Clear all categories and their nested articles in the trash": "Trash has been cleared",
            "Category": "Category",
            "Choose": "Choose",
            "Checking account": "Checking account",
            "Correspondent account": "Correspondent account",
            "Choose from all types of tests": "Choose from all types of tests",
            "Copy of the conclusion": "Conclusion copy",
            "Check": "Check",
            "Confirm file deletion": "Confirm file deletion",
            "Confirm": "Confirm",
            "Contact email": "Contact email",
            "Creating a user": "Creating a user",
            "Current photo": "Current photo",
            "Checkout": "Checkout",
            "Comparison of DNA profiles": "Comparison of DNA profiles",
            "Confirm deletion": "Confirm deletion",
            "Child": "Child",
            "Chewing gum": "Chewing gum",
            "Cigarette butts": "Cigarette butts",
            "Comb": "Comb",
            "current total size": "current total size",
            "Current data": "Current data",
            "Content manager": "Content manager",
            "Confirm partner company data": "Confirm partner company data",
            "Confirm data": "Confirm data",
            "Change user data": "Change user data",
            "Create new appeal": "Create new appeal",
            "Created": "Created",
            "Correspondence": "Correspondence",
            "Change status": "Change status",
            "Cards quantity": "Cards quantity",
            "Clear tests in trash": "Clear tests in trash",
            "Contact phone": "Contact phone",
            "Call": "Call",
            "Clear the filters": "Clear the filters",
            "Current preview": "Current preview",
            "Confirm the deletion": "Confirm the deletion",
            "Contacts can't be empty": "Contacts can't be empty",
            "Current documents": "Current documents",
            "Clear the form with the participant": "Clear the form with the participant",
            "Create": "Create",
            "Comments on all samples": "Comments on all samples",
            "Company phone": "Company phone",

            //D
            "DNA Tests": "DNA Tests",
            "Date of creation": "Creation date",
            "Description": "Description",
            "Delete": "Delete",
            "Description of family ties": "Description of family ties",
            "Documents from the laboratory": "Laboratory documents",
            "Dialogue with the laboratory regarding the test": "Contact the laboratory regarding the test",
            "Download the document": "Download the document",
            "Date of birth": "Date of birth",
            "Description of another type of sample": "Another sample type description",
            "Download the file": "Download the file",
            "Delete the file": "Delete the file",
            "Deleting": "Deleting",
            "Documents": "Documents",
            "DNA Beauty \"Beauty\"": "DNA Beauty \"Beauty\"",
            "Determination of sperm in the material": "Sperm detection in the material",
            "Determination of blood in the material": "Blood detection in the material",
            "Describe another test": "Describe another test",
            "Disposable/cutlery": "Disposable/cutlery",
            "Drinking straw": "Drinking straw",
            "Describe the sample": "Describe the sample",
            "Describe family ties (for relationship tests)": "Describe family ties (for relationship tests)",
            "documents": "documents",
            "Date of birth cannot be later than today.": "Date of birth cannot be greater than today.",
            "Degree of relationship is required.": "Degree of relationship is required.",
            "Description for non-standard tests": "Description for non-standard tests",
            "Declared physical address": "Declared physical address",
            "Declared contact": "Declared contact",
            "Do you want to call a courier to collect biomaterial?": "Do you want to call a courier to collect biomaterial?",
            "Degrees of relationship are loading": "Degrees of relationship are loading",
            "Dates for the end customer": "Dates for the end customer",
            "Date of sample collection": "Date of sample collection",
            "Document types are loading": "Document types are loading",
            "Document number is required": "Document number is required",
            "Document number": "Document number",

            //E
            "Entrance": "Log in",
            "Email": "Email",
            "Empty articles from trash": "Empty trash",
            "Error with text": "An error occurred on the server. Error text: {{error}}.",
            "Error with code and text": "An error has occurred on the server. Error code: {{code}}. Error text: {{error}}.",
            "Editing the category": "Editing the category",
            "Edit": "Edit",
            "Edit the article": "Edit the article",
            "Email to log into your personal account": "Email to log into your personal account",
            "Edit the user": "Edit the user",
            "Editing the user": "Editing the user",
            "Edit the partner": "Edit the partner",
            "Edit test participant": "Edit test participant",
            "Ethnic (male line)": "Ethnic (male line)",
            "Ethnic (female line)": "Ethnic (female line)",
            "Earwax": "Earwax",
            "Email is required.": "Email is required.",
            "Email cannot be empty.": "Email cannot be empty.",
            "Employee contacts": "Employee contacts",
            "Edit the test": "Edit the test",
            "Edit the DNA test order": "Edit the DNA test order",
            "Error code": "Error code",
            "End customer": "End customer",

            //F
            "Failed to log in. There is an error on the server.": "Failed to login. There was an error on the server.",
            "Feedback": "Feedback",
            "Feedback from users": "Feedback from users",
            "Full name": "Full name",
            "Fri": "Fri",
            "Filter by status": "Filter by status",
            "Fresh first": "New first",
            "First with unread replies": "First with unread answers",
            "First with read answers": "First with read answers",
            "from A to Z": "from A to Z",
            "from Z to A": "from Z to A",
            "First you need to create categories, then it will be possible to add articles to them": "You need to create the category first and then you can add articles",
            "Full name of the legal entity": "Full name of a legal entity",
            "Features of pregnancy": "Pregnancy peculiarities",
            "File name": "File name",
            "files": "files",
            "Full name or nickname": "Full name or nickname",
            "File size": "File size",
            "Filter by roles": "Filter by roles",
            "From": "From",
            "Full name or legal name": "Full name or legal name",
            "Fetal gender": "Fetal gender",
            "Father's brother": "Father's brother",
            "Father": "Father",
            "Father's sister": "Father's sister",
            "Feminine pad/tampon": "Feminine pad/tampon",
            "Features of pregnancy (for pregnant women)": "Pregnancy peculiarities (for pregnant women)",
            "Forms/protocols": "Forms/protocols",
            "File deleted.": "File deleted.",
            "Full name is required.": "Full name is required.",
            "Full name or nickname is required.": "Full name or nickname is required.",
            "Full name cannot be empty.": "Full name cannot be empty.",
            "Full description": "Full description",
            "For employees: if you want partners to be able to order non-standard tests where they write the name themselves, then this list of tests must include a test with the pseudonym: testTypeOther.": "For employees: if you want partners to be able to order non-standard tests where they write the name themselves, then this list of tests must include a test with the pseudonym: testTypeOther.",
            "For some reason, the server did not return any roles.": "For some reason, the server did not return any roles.",
            "For some reason, the server did not return any managers.": "For some reason, the server did not return any managers.",
            "For some reason, the server did not return any partner types.": "For some reason, the server did not return any partner types.",
            "For laboratory marks": "For laboratory marks",
            "For laboratory marks for the sample": "For laboratory marks for the sample",

            //G
            "Gestational age": "Gestational age",
            "Genetic profile": "Genetic profile",
            "Gilbert's syndrome": "Gilbert's syndrome",
            "Greasy cuffs and/or collars": "Greasy cuffs and/or collars",
            "Gestation period (for pregnant women)": "Gestation period (for pregnant women)",
            "Go back": "Go back",
            "Gender of the participant": "Gender of the participant",
            "Genders are loading": "Genders are loading",

            //H
            "Half-sibling on mother's side": "Half-sibling on mother's side",
            "Half-sibling on father's side": "Half-sibling on father's side",
            "Hair with bulb": "Hair with bulb",
            "Histological specimen/paraffin block": "Histological specimen/paraffin block",
            "Handkerchief or napkin with nasal mucus": "Handkerchief or napkin with nasal mucus",

            //I
            "Inlab Genetics": "Inlab Genetics",
            "Index": "Index",
            "Indicators": "Indicators",
            /*ИНН*/
            "Individual taxpayer number": "Individual Taxpayer Identification Number",
            "Informational": "Informational",
            "Institution employee": "Institution employee",
            "Informed consent": "Informed consent",
            "Invalid date.": "Invalid date.",
            "Invalid email.": "Invalid email.",
            "Image": "Image",
            "Run import of tests from Bitrix": "Run import of tests from Bitrix",
            "If you have a tracking number for the shipment with biological material for this order, please provide it.": "If you have a tracking number for the shipment with biological material for this order, please provide it.",
            "Info for a courier": "Info for a courier",
            "Information about participants": "Information about participants",

            //J
            "Job title": "Job title",

            //K
            "Knowledge base": "Knowledge base",
            "Kb": "Kb",
            "Kinship": "Kinship",

            //L
            "Loading...": "Loading…",
            "Log in": "Login",
            "Loading the user": "Loading the user",
            "Loading orders": "Loading orders",
            "Legal status": "Legal status",
            "Loading ordered test types": "Loading ordered test types",
            "Loading categories": "Loading categories",
            "Last modification": "Last modification",
            "Loading managers": "Loading managers",
            "Limited Liability Company \"Company Name\"": "Limited Liability Company \"Company Name\"",
            "LLC \"Company Name\"": "LLC \"Company Name\"",
            "Legal address": "Legal address",
            "Loading roles": "Loading roles",
            "Loading test types": "Loading test types",
            "Loading the order": "Loading the order",
            "Load more": "Load more",
            "Loading senior managers": "Loading senior managers",
            "Loading partners": "Loading partners",
            "Loading available roles": "Loading available roles",
            "Loading users": "Loading users",
            "Liquid blood": "Liquid blood",
            "loaded": "loaded",
            "Loaded": "Loaded",
            "Loading applications": "Loading applications",
            "Loading the application": "Loading the application",
            "Last update": "Last update",
            "Login is required.": "Login is required.",
            "Legal address is required.": "Legal address is required.",
            "Legal status is required.": "Legal status is required.",
            "Loading articles": "Loading articles",
            "Loading your personal manager": "Loading your personal manager",
            "Loading the appeal": "Loading the appeal",
            "Laboratory": "Laboratory",
            "Loading appeals": "Loading appeals",
            "Loading tests": "Loading tests",
            "Loading tests in trash count": "Loading tests in trash count",
            "Legal statuses are loading": "Legal statuses are loading",
            "Loading partner types": "Loading partner types",
            "Loading the module": "Loading the module",

            //M
            "My Orders": "My Orders",
            "More": "More",
            "Mon": "Mon",
            "Message": "Message",
            "Maximum size": "Maximum size",
            "Mb": "Mb",
            /*ОГРН*/
            "Main state registration number": "Primary State Registration Number",
            "Main": "Main",
            "Maximum size of all files at once": "Maximum size of all files at once",
            "Manager": "Manager",
            "mtDNA": "mtDNA",
            "Motherhood": "Motherhood",
            "My Genes \"Light\"": "My Genes \"Light\"",
            "My Genes \"Figure\"": "My Genes \"Figure\"",
            "My Genes \"Neurotype\"": "My Genes \"Neurotype\"",
            "My Genes \"Full Health\"": "My Genes \"Full Health\"",
            "Maternal grandmother": "Maternal grandmother",
            "Mother's brother": "Mother's brother",
            "Maternal grandfather": "Maternal grandfather",
            "Mother": "Mother",
            "Mother's sister": "Mother's sister",
            "Mugs and glasses": "Mugs and glasses",
            "maximum": "maximum",
            "Message subject": "Message subject",
            "Message text": "Message text",
            "Maximum overall size": "Maximum overall size",
            "Minimum date: 01/01/1000.": "Minimum date: 01/01/1000.",
            "Main state registration number is required.": "Primary State Registration Number is required.",
            "Main state registration number must be a number.": "Primary State Registration Number must be a number.",
            "Main state registration number consists of 13 digits.": "Primary State Registration Number consists of 13 digits.",
            "Main info": "Main info",
            "Mailing address": "Mailing address",
            
            //N
            "Not chosen": "Not chosen",
            "No": "No",
            "Name of category": "Category title",
            "Name for the article": "Article title",
            "Name of the bank": "Bank name",
            "Note": "Note",
            "Needed": "Needed",
            "Not needed": "Not needed",
            "Number by order": "Number by order",
            "Next": "Next",
            "NIPT Veracity (3 pathologies)": "NIPT Veracity (3 pathologies)",
            "NIPT Veracity (8 pathologies)": "NIPT Veracity (8 pathologies)",
            "NIPT Veracity (12 pathologies)": "NIPT Veracity (12 pathologies)",
            "NIPT VeraGen (12 pathologies + 100 monogenic)": "NIPT VeraGen (12 pathologies + 100 monogenic)",
            "Nationality": "Nationality",
            "Nephew": "Nephew",
            "Niece": "Niece",
            "Nails": "Nails",
            "New data": "New data",
            "New": "New",
            "Nested categories of articles": "Nested categories of articles",
            "No search results": "No search results",
            "No physical addresses": "No physical addresses",
            "No tests": "No tests",
            "No data": "No data",
            "Name": "Name",
            "New preview": "New preview",
            "No preview for this test": "No preview for this test",
            "Number in Bitrix": "Number in Bitrix",
            "Number of the order in Bitrix": "Number of the order in Bitrix",
            "Number of the document": "Number of the document",

            //O
            "Order number": "Order number",
            "Open": "Open",
            "Open dialog": "Open dialog",
            "Old first": "Old first",
            "Open the order": "Open the order",
            "Order for DNA testing": "Order for DNA testing",
            "Other important information for the laboratory": "Other important information for the laboratory",
            "Order creation date": "Order creation date",
            "Other": "Other",
            "Order ID": "Order ID",
            "Order a DNA test": "Order a DNA test",
            "Origin of the family": "Origin of the family",
            "Oral swab (buccal epithelium)": "Oral swab (buccal epithelium)",
            "Order the test": "Order the test",
            "Open the file": "Open the file",
            "Order ID from Bitrix": "Order ID from Bitrix",
            "Order number in Bitrix": "Order number in Bitrix",
            "Organization phone number": "Organization phone number",

            //P
            "Password": "Password",
            "Place a new order": "Place a new order",
            "Phone": "Phone",
            "Parent category": "Parent category",
            "Preview for the article": "Article preview",
            "Physical address": "Physical address",
            "Partner": "Partner",
            "Partner ID": "Partner ID",
            "Partner documents attached to the order": "Partner documents attached to the order",
            "Print the document": "Print the document",
            "Previous order number": "Previous order number",
            "Participant ID": "Participant ID",
            "Print the file": "Print the file",
            "Partner loading": "Partner loading",
            "Profile": "Profile",
            "Paternity": "Paternity",
            "Paternity+maternity": "Paternity + maternity",
            "Prenatal fatherhood": "Prenatal fatherhood",
            "Paternal grandmother": "Paternal grandmother",
            "Paternal grandfather": "Paternal grandfather",
            "Participant from an earlier test": "Previous test participant",
            "Previous test order number": "Previous test order number",
            "Pacifier": "Pacifier",
            "Photo": "Photo",
            "Physical addresses": "Physical addresses",
            "Processed": "Processed",
            "Partner name": "Partner name",
            "Page not found": "Page not found",
            "Partner info": "Partner info",
            "Price": "Price",
            "Physical addresses can't be empty": "Physical addresses can't be empty",
            "Print all barcodes": "Print all barcodes",
            "Print the barcode": "Print the barcode",
            "Phone number for the courier": "Phone number for the courier",
            "Partner type": "Partner type",
            "Pregnancy peculiarities are loading": "Pregnancy peculiarities are loading",
            "Place an order on behalf of a partner": "Place an order on behalf of a partner",
            "Provisional date of recording": "Provisional date of recording",
            "Print the order form": "Print the order form",

            //Q

            //R
            "Requests for change of personal data": "Requests to change personal data",
            "Rows in table": "Rows in table",
            "Register a new partner": "Register a new partner",
            /*КПП*/
            "Registration reason code": "Registration reason code",
            "Role": "Role",
            "Register": "Register",
            "Return of samples": "Return of samples",
            "Result": "Result",
            "Relation degree": "Relation degree",
            "Repeat password": "Repeat password",
            "Roles": "Roles",
            "Registration date": "Registration date",
            "Razor": "Razor",
            "Ready profile": "Ready profile",
            "Return of samples (only in case of remaining balance)": "Samples return (only in case of remaining)",
            "Reject": "Reject",
            "Report an error or complain to a manager": "Report an error or complain to a manager",
            "Request for editing": "Request for editing",

            //S
            "Schedule": "Schedule",
            "Sat": "Sat",
            "Sun": "Sun",
            "Status": "Status",
            "StatusNew": "New",
            "StatusInProgress": "In progress",
            "StatusReady": "Ready",
            "Select from the types of test you have previously ordered": "Select from the test types you have previously ordered",
            "StatusClosed": "Closed",
            "Sort by creation date": "Sort by creation date",
            "Sort by presence of unread replies": "Sort by unread replies",
            "Sort by alphabet": "Sort by alphabet",
            "Status updating": "Status updating",
            "Search by name": "Search by name",
            "Search by article update date": "Search by article update date",
            "Sort by name": "Sort by name",
            "Sort by date updated": "Sort by date updated",
            "Save": "Save",
            "Short meaning of the article": "Article summary",
            "Select a manager": "Select a manager",
            "Short name of the legal entity": "Legal entity short name",
            "Study period": "Study period",
            "Sign": "Sign",
            "Select a file": "Select a file",
            "show ID": "show ID",
            "Sample type": "Sample type",
            "Select roles": "Select roles",
            "Select photo": "Select photo",
            "Select a senior manager": "Select a senior manager",
            "Select a new photo": "Select a new photo",
            "Select which manager to transfer the partners of the deleted manager to": "Select which manager to transfer the partners of the deleted manager to",
            "Select which partner to transfer the orders of the removed partner to": "Select which partner to transfer the orders of the removed partner to",
            "Select which senior manager to transfer the managers of the removed senior manager to": "Select which senior manager to transfer the managers of the removed senior manager to",
            "Senior manager": "Senior manager",
            "Search by registration date": "Search by registration date",
            "Search by email": "Search by email",
            "Search by full name or legal entity": "Search by full name or legal entity",
            "Step 1: Select a test": "Step 1: Select a test",
            "Step 2: Specify Participants": "Step 2: Specify Participants",
            "Step 3: Advanced": "Step 3: Advanced",
            "Select test type": "Select test type",
            "Standard term according to price list": "Price list standard term",
            "Sample description": "Sample description",
            "Soft tissue (parts of organs/muscles/skin)": "Soft tissue (parts of organs/muscles/skin)",
            "Stains on clothing or fabric": "Stains on clothing or fabric",
            "Sperm on fabric / condom": "Sperm on fabric / condom",
            "Scanned copies of documents": "Scanned copies of documents",
            "Send": "Send",
            "Supreme administrator": "Supreme administrator",
            "Short name is required.": "Short name is required.",
            "Select a role": "Select a role",
            "Show categories in trash can": "Show categories in trash can",
            "Select a category": "Select a category",
            "Search": "Search",
            "Search results": "Search results",
            "Send with corrections": "Send with corrections",
            "Search articles": "Search articles",
            "Subject": "Subject",
            "Search by text": "Search by text",
            "Search by subject": "Search by subject",
            "Sorting by subject": "Sorting by subject",
            "Search by title": "Search by title",
            "Serial number": "Serial number",
            "Short description": "Short description",
            "Show processed appeals too": "Show processed appeals too",
            "Select date": "Select date",
            "Select time of the day": "Select time of the day",
            "Sample types are loading": "Sample types are loading",
            "Select a preview for this test": "Select a preview for this test",
            "Study periods are loading": "Study periods are loading",
            "Select the partner type": "Select the partner type",
            "Something went wrong. Error code:": "Something went wrong. Error code:",
            "Select the partner": "Select the partner",
            "Search by creation date": "Search by creation date",
            "Search by order number": "Search by order number",
            "Search by participant's name": "Search by participant's name",
            "Search by a number in Bitrix": "Search by a number in Bitrix",
            "Search by a number of the order in Bitrix": "Search by a number of the order in Bitrix",
            "Select a partner": "Select a partner",
            "Search by order id": "Search by order id",

            //T
            "Tue": "Tue",
            "Thu": "Thu",
            "Type of test": "Type of test",
            "Test participants": "Test participants",
            "There are no orders.": "There are no orders.",
            "Test": "Test",
            "This action cannot be undone": "This action cannot be undone",
            "The trash can has been emptied": "The trash can has been emptied",
            "The category was successfully saved": "The category was successfully saved",
            "The category has been successfully updated": "The category has been successfully updated",
            "There are no articles in the selected category": "There are no articles in the selected category",
            "There are no available categories": "There are no available categories",
            "The preview is updated as soon as you select a new picture, i.e. the preview will be updated even if you don't click \"Save\".": "The preview is updated as soon as you select a new picture, i.e. the preview will be updated even if you don't click \"Save\".",
            "There is not a single physical address, you must add at least one.": "There is not any physical address, you must add at least one.",
            "The partner has been successfully registered. Soon, the partner will receive a letter, on email address, chosen for login with the credentials for enter.": "The partner has been successfully registered. Soon, the partner will receive a letter, on email address, chosen for login with the credentials for enter.",
            "Track number of sent samples": "Track number of sent samples",
            "Translation of the conclusion into a foreign language": "Conclusion translation into a foreign language",
            "Test other": "Test other",
            "There are no files.": "There are no files.",
            "The essence of the document": "Subject of the document",
            "The essence of the document is not selected": "Subject of the document is not selected",
            "The user was successfully saved.": "The user was successfully saved.",
            "The user has been successfully updated.": "The user has been successfully updated.",
            "The user was successfully deleted.": "The user was successfully deleted.",
            "Transfer to another partner": "Transfer to another partner",
            "To": "To",
            "The partner has been successfully updated.": "The partner has been successfully updated.",
            "Treason test": "Treason test",
            "Toothbrush": "Toothbrush",
            "Toothpicks": "Toothpicks",
            "Teeth": "Teeth",
            "total size up to": "total size up to",
            "The application has already been processed.": "The application has already been processed.",
            "The decision was successfully made.": "The decision was successfully made.",
            "The order for DNA testing has been successfully created.": "The order for DNA testing has been successfully created.",
            "The password must not be shorter than 6 characters.": "The password must not be shorter than 6 characters.",
            "The password is required.": "The password is required.",
            "The files were successfully uploaded": "The files were successfully uploaded",
            "TIN must be a number.": "TIN must be a number.",
            "The TIN consists of 10 digits.": "The TIN consists of 10 digits.",
            "TIN is required to be filled out.": "TIN is required to be filled out.",
            "The registration reason code must be a number.": "The registration reason code must be a number.",
            "The registration reason code consists of 9 digits.": "The registration reason code consists of 9 digits.",
            "The registration reason code is required to be filled out.": "The registration reason code is required to be filled out.",
            "The checking account must be a number.": "The checking account must be a number.",
            "The checking account consists of 20 digits.": "The checking account consists of 20 digits.",
            "The checking account is required to be filled out.": "The checking account is required to be filled out.",
            "The correspondent account must be a number.": "The correspondent account must be a number.",
            "The correspondent account consists of 20 digits.": "The correspondent account consists of 20 digits.",
            "The correspondent account starts with 301.": "The correspondent account starts with 301.",
            "The correspondent account is required to be filled out.": "The correspondent account is required to be filled out.",
            "The name of the bank is required to be filled out.": "The name of the bank is required to be filled out.",
            "The sign is required to be filled out.": "The sign is required to be filled out.",
            "The test type is required.": "The test type is required.",
            "The previous order number is required.": "The previous order number is required.",
            "The study period is required to be filled out.": "The study period is required to be filled out.",
            "The description of the sample is required to be filled out.": "The description of the sample is required to be filled out.",
            "The sample type is required.": "The sample type is required.",
            "The test description is required.": "The test description is required.",
            "The field \"Who collected the samples\" is required.": "The field \"Who collected the samples\" is required.",
            "The article was successfully saved.": "The article was successfully saved.",
            "The phone cannot be empty.": "The phone cannot be empty.",
            "The physical address cannot be empty.": "The physical address cannot be empty.",
            "There are no nested categories.": "There are no nested categories.",
            "The password must not be shorter than some characters.": "The password must not be shorter than {{count}} characters.",
            "There are unreliable data": "There are unreliable data",
            "Type a new value": "Type a new value",
            "There are no contacts": "There are no contacts",
            "The request performed successfully.": "The request performed successfully.",
            "Text": "Text",
            "There are no appeals": "There are no appeals",
            "Title": "Title",
            "The new test has been successfully created": "The new test has been successfully created",
            "The test has been successfully updated": "The test has been successfully updated",
            "The test in trash": "The test in trash",
            "Tests in trash": "Tests in trash",
            "Type a complete email": "Type a complete email",
            "The courier was successfully called. Expect.": "The courier was successfully called. Expect.",
            "The opened order": "The opened order",
            "The test data isn't loaded": "The test data isn't loaded",
            "This appeal is closed.": "This appeal is closed.",
            "The order is in trash": "The order is in trash",
            "The order is loading": "The order is loading",
            "The barcodes are fetching": "The barcodes are fetching",
            "The address where the courier should arrive": "The address where the courier should arrive",
            "Type of partner": "Type of partner",
            "This field is required": "This field is required",
            "Time zone": "Time zone",
            "The participant editing/adding form is not empty. Please add/edit the participant or clear the form.": "The participant editing/adding form is not empty. Please add/edit the participant or clear the form.",
            "Type": "Type",
            "Type of the provided document": "Type of the provided document",

            //U
            "Users": "Users",
            "User ID": "User ID",
            "Urgent delivery of the report by courier": "Urgent conclusion delivery by courier",
            "Upload": "Upload",
            "Uploading documents": "Uploading documents",
            "Upload files": "Upload files",
            "Uploaded documents": "Uploaded documents",
            "Update": "Update",
            "Until the court's decision": "Until the court's decision",
            "Unauthorized user": "Unauthorized user",
            "Updated": "Updated",
            "Unfortunately, an error occurred while loading partner types.": "Unfortunately, an error occurred while loading partner types.",
            "Unfortunately, an error occurred while loading managers.": "Unfortunately, an error occurred while loading managers.",
            "Unfortunately, an error occurred while loading roles for a contact.": "Unfortunately, an error occurred while loading roles for a contact.",
            "Unfortunately, roles weren't loaded.": "Unfortunately, roles weren't loaded.",

            //V
            "Valid extensions": "Valid extensions",
            "valid formats": "valid formats",
            "Value": "Value",
            "View application": "View application",

            //W
            "Wed": "Wed",
            "Written consultation with a test specialist": "Written consultation with a test specialist",
            "Who collected the samples": "Who collected the samples",
            "What to do with existing orders from this partner?": "What to do with existing orders from this partner?",
            "Watch strap": "Watch strap",
            "Write a message": "Write a message",
            "Who collected samples variants are loading": "Who collected samples variants are loading",
            "When the courier will arrive, time from": "When the courier will arrive, time from",
            "When the courier will arrive, time to": "When the courier will arrive, time to",

            //X
            "X chromosome": "X chromosome",

            //Y
            "You have successfully logged in. You can go to the section you need.": "You have successfully logged in. You can go to the section you need.",
            "You've successfully exited.": "You've successfully logged out.",
            "Your manager": "Your manager",
            "Yes": "Yes",
            "You can download up to": "You can download up to",
            "Your profile": "Your profile",
            "Your account roles": "Your account roles",
            "You cannot add more participants.": "You cannot add more participants.",
            "Y chromosome": "Y chromosome",
            "You can upload": "You can upload",
            "You have uploaded the maximum number of files.": "You have uploaded the maximum number of files.",
            "You have exceeded the maximum files upload size.": "You have exceeded the maximum size of uploaded files.",
            "You do not have access to this section.": "You do not have access to this section.",
            "You are making too many requests.": "You are making too many requests.",
            "You cannot add more than 5 participants.": "You cannot add more than 5 participants.",
            "You": "You",
            "You need to choose the type of partner.": "You need to choose the type of partner.",

            //Z


            "1 day(24 hours)": "1 day (24 hours)",
            "2 days(48 hours)": "2 days (48 hours)",
        }
    },
    ru: {
        translation: {
            //A
            "All Orders": "Все заказы",
            "An error occurred on the server while trying to log out. Reload the page and try again.": "При попытке выйти произошла ошибка на сервере. Перезагрузите страницу и попробуйте снова.",
            "Another test": "Другой тест",
            "Articles": "Статьи",
            "Articles in trash can": "Статьи в корзине",
            "Add a category": "Добавить категорию",
            "Adding a category": "Добавление категории",
            "Add": "Добавить",
            "Articles in the current category and subcategories": "Статей в текущей категории и подкатегориях",
            "Article in the trash can": "Статья в корзине",
            "Author": "Автор",
            "Available extensions": "Доступные расширения",
            "Adding of a physical address": "Добавление физического адреса",
            "Adding a contact": "Добавление контакта",
            "An error occurred while saving the partner.": "При сохранении партнера произошла ошибка.",
            "About the partner": "О партнере",
            "Additional information": "Дополнительная информация",
            "Additional services": "Дополнительные услуги",
            "Act": "Акт",
            "Add a document": "Добавить документ",
            "Added contacts": "Добавленные контакты",
            "Add a user": "Добавить пользователя",
            "Are you sure you want to delete the user?": "Вы действительно хотите удалить пользователя?",
            "Address": "Адрес",
            "Added addresses": "Добавленные адреса",
            "An error occurred while updating the partner.": "При обновлении партнера произошла ошибка.",
            "Add test participant": "Добавить участника теста",
            "Autosomal markers": "Аутосомные маркеры",
            "Are you sure you want to remove the member": "Вы действительно хотите удалить участника",
            "Additional participant": "Дополнительный участник",
            "Abortive material": "Абортивный материал",
            "Application date": "Дата заявки",
            "Application for change of personal data": "Заявка на смену персональных данных",
            "Access is denied": "Доступ запрещен",
            "Attach a file": "Прикрепить файл",
            "Administrator": "Администратор",
            "Accountant": "Бухгалтер",
            "An error occured during the request.": "Во время запроса произошла ошибка.",
            "Add an address": "Добавить адрес",
            "Add a contact": "Добавить контакт",
            "Account info": "Информация об аккаунте",
            "Appeal": "Обращение",
            "Add a test": "Добавить тест",
            "Alias": "Псевдоним",
            "Are you sure": "Вы уверены",
            "A file isn't chosen": "Файл не выбран",
            "Attributes are loading": "Атрибуты загружаются",
            "A partner is required": "Необходимо выбрать партнера",
            "Agreed date of recording": "Согласованная дата записи",
            "Appeal types are loading": "Типы обращений загружаются",

            //B
            /*БИК*/
            "Bank identification code": "Банковский Идентификационный Код",
            "Back": "Назад",
            "By the tribunal's decision": "По решению суда",
            "Bones": "Кости",
            "Blood plasma": "Плазма крови",
            "Brought by the client": "Принес клиент",
            "By participant on the place": "Участник сам на месте",
            "BIC must be a number.": "БИК должен быть числом.",
            "BIC consists of 9 digits.": "БИК состоит из 9 цифр.",
            "BIC is required to be filled out.": "БИК обязателен для заполнения.",
            "Bill": "Счет",

            //C
            "Control panel": "Панель управления",
            "Contacts of your personal manager": "Контакты вашего персонального менеджера",
            "Close": "Закрыть",
            "Clear": "Очистить",
            "Create an article": "Создать статью",
            "Categories": "Категории",
            "Clear all articles in the trash": "Очистить все статьи в корзине",
            "Cancel": "Отмена",
            "Categories in trash": "Категорий в корзине",
            "Clear categories and articles from the trash": "Очистить категории и статьи из корзины",
            "Categories and articles have been cleared from the trash": "Категории и статьи были очищены из корзины",
            "Clear all categories and their nested articles in the trash": "Очистить все категории и вложенные в них статьи в корзине",
            "Category": "Категория",
            "Choose": "Выберите",
            "Checking account": "Расчетный счет",
            "Correspondent account": "Корреспондентский счет",
            "Choose from all types of tests": "Выбор из всех видов тестов",
            "Copy of the conclusion": "Копия заключения",
            "Check": "Счет",
            "Confirm file deletion": "Подтвердите удаление файла",
            "Confirm": "Подтвердить",
            "Contact email": "Контактная электронная почта",
            "Creating a user": "Создание пользователя",
            "Current photo": "Текущее фото",
            "Checkout": "Оформить заказ",
            "Comparison of DNA profiles": "Сравнение профилей ДНК",
            "Confirm deletion": "Подтвердите удаление",
            "Child": "Ребенок",
            "Chewing gum": "Жевательная резинка",
            "Cigarette butts": "Окурки",
            "Comb": "Расческа",
            "current total size": "текущий общий размер",
            "Current data": "Текущие данные",
            "Content manager": "Контент-менеджер",
            "Confirm partner company data": "Подтвердите данные компании партнера",
            "Confirm data": "Подтверждаю данные",
            "Change user data": "Изменить пользовательские данные",
            "Create new appeal": "Создать новое обращение",
            "Created": "Создано",
            "Correspondence": "Переписка",
            "Change status": "Изменить статус",
            "Cards quantity": "Количество карточек",
            "Clear tests in trash": "Очистить тесты из корзины",
            "Contact phone": "Контактный телефон",
            "Call": "Вызвать",
            "Clear the filters": "Очистить фильтры",
            "Current preview": "Текущее превью",
            "Confirm the deletion": "Подтвердите удаление",
            "Contacts can't be empty": "Контакты не могут быть пустыми",
            "Current documents": "Текущие документы",
            "Clear the form with the participant": "Очистить форму с участником",
            "Create": "Создать",
            "Comments on all samples": "Комментарии ко всем образцам",
            "Company phone": "Телефон компании",

            //D
            "DNA Tests": "ДНК тесты",
            "Date of creation": "Дата создания",
            "Description": "Описание",
            "Delete": "Удалить",
            "Description of family ties": "Описание родственных связей",
            "Documents from the laboratory": "Документы от лаборатории",
            "Dialogue with the laboratory regarding the test": "Диалог с лабораторией по тесту",
            "Download the document": "Скачать документ",
            "Date of birth": "Дата рождения",
            "Description of another type of sample": "Описание другого вида образца",
            "Download the file": "Скачать файл",
            "Delete the file": "Удалить файл",
            "Deleting": "Удаление",
            "Documents": "Документы",
            "DNA Beauty \"Beauty\"": "DNA Beauty «Красота»",
            "Determination of sperm in the material": "Определение спермы в материале",
            "Determination of blood in the material": "Определение крови в материале",
            "Describe another test": "Опишите другой тест",
            "Disposable/cutlery": "Одноразовые / столовые приборы",
            "Drinking straw": "Питьевая трубочка",
            "Describe the sample": "Опишите образец",
            "Describe family ties (for relationship tests)": "Опишите родственные связи (для тестов на родство)",
            "documents": "документов",
            "Date of birth cannot be later than today.": "Дата рождения не может быть позднее, чем сегодня.",
            "Degree of relationship is required.": "Степень родства обязательна для заполнения.",
            "Description for non-standard tests": "Описание для нестандартных тестов",
            "Declared physical address": "Заявленный физический адрес",
            "Declared contact": "Заявленный контакт",
            "Do you want to call a courier to collect biomaterial?": "Хотите вызвать курьера для забора биоматериала?",
            "Degrees of relationship are loading": "Степени родства загружаются",
            "Dates for the end customer": "Даты для конечного заказчика",
            "Date of sample collection": "Дата взятия образца",
            "Document types are loading": "Типы документов загружаются",
            "Document number is required": "Номер документа обязателен",
            "Document number": "Номер документа",

            //E
            "Entrance": "Вход",
            "Email": "Электронная почта",
            "Empty articles from trash": "Очистить статьи из корзины",
            "Error with text": "Произошла ошибка на сервере. Текст ошибки: {{error}}.",
            "Error with code and text": "Произошла ошибка на сервере. Код ошибки: {{code}}. Текст ошибки: {{error}}.",
            "Editing the category": "Редактирование категории",
            "Edit": "Редактировать",
            "Edit the article": "Редактировать статью",
            "Email to log into your personal account": "Электронная почта для входа в личный кабинет",
            "Edit the user": "Редактировать пользователя",
            "Editing the user": "Редактирование пользователя",
            "Edit the partner": "Редактировать партнера",
            "Edit test participant": "Отредактировать участника теста",
            "Ethnic (male line)": "Этнический (мужская линия)",
            "Ethnic (female line)": "Этнический (женская линия)",
            "Earwax": "Ушная сера",
            "Email is required.": "Электронная почта обязательна для заполнения.",
            "Email cannot be empty.": "Электронная почта не может быть пустой.",
            "Employee contacts": "Контакты сотрудников",
            "Edit the test": "Редактировать тест",
            "Edit the DNA test order": "Редактировать заказ на ДНК-тестирование",
            "Error code": "Код ошибки",
            "End customer": "Конечный заказчик",

            //F
            "Failed to log in. There is an error on the server.": "Войти не удалось. Ошибка на сервере.",
            "Feedback": "Обратная связь",
            "Feedback from users": "Обратная связь от пользователей",
            "Full name": "ФИО",
            "Fri": "Пт",
            "Filter by status": "Фильтр по статусу",
            "Fresh first": "Сначала свежие",
            "First with unread replies": "Сначала с НЕ прочитанными ответами",
            "First with read answers": "Сначала с прочитанными ответами",
            "from A to Z": "от А до Я",
            "from Z to A": "от Я до А",
            "First you need to create categories, then it will be possible to add articles to them": "Сначала нужно создать категории, потом будет возможно добавлять в них статьи",
            "Full name of the legal entity": "Полное наименование юридического лица",
            "Features of pregnancy": "Особенности беременности",
            "File name": "Имя файла",
            "files": "файлов",
            "Full name or nickname": "ФИО или псевдоним",
            "File size": "Размер файла",
            "Filter by roles": "Фильтр по ролям",
            "From": "От",
            "Full name or legal name": "ФИО или название юридического лица",
            "Fetal gender": "Пол плода",
            "Father's brother": "Брат отца",
            "Father": "Отец",
            "Father's sister": "Сестра отца",
            "Feminine pad/tampon": "Женская прокладка / тампон",
            "Features of pregnancy (for pregnant women)": "Особенности беременности (для беременных)",
            "Forms/protocols": "Бланки/протоколы",
            "File deleted.": "Файл удален.",
            "Full name is required.": "Полное наименование обязательно для заполнения.",
            "Full name or nickname is required.": "ФИО или псевдоним обязателен для заполнения.",
            "Full name cannot be empty.": "ФИО не может быть пустым.",
            "Full description": "Полное описание",
            "For employees: if you want partners to be able to order non-standard tests where they write the name themselves, then this list of tests must include a test with the pseudonym: testTypeOther.": "Для сотрудников: если вы хотите, чтобы партнеры имели возможность заказывать не стандартные тесты, где они сами пишут название, тогда в этом списке тестов должен быть тест с псевдонимом: testTypeOther.",
            "For some reason, the server did not return any roles.": "По какой-то причине сервер не вернул ни одну роль.",
            "For some reason, the server did not return any managers.": "По какой-то причине сервер не вернул ни одного менеджера.",
            "For some reason, the server did not return any partner types.": "По какой-то причине сервер не вернул ни один тип партнера.",
            "For laboratory marks": "Для лабораторных отметок",
            "For laboratory marks for the sample": "Для лабораторных отметок к образцу",

            //G
            "Gestational age": "Срок беременности",
            "Genetic profile": "Генетический профиль",
            "Gilbert's syndrome": "Синдром Жильбера",
            "Greasy cuffs and/or collars": "Засаленные манжеты и/или вороты",
            "Gestation period (for pregnant women)": "Срок беременности (для беременных)",
            "Go back": "Вернуться",
            "Gender of the participant": "Пол участника",
            "Genders are loading": "Варианты полов загружаются",

            //H
            "Half-sibling on mother's side": "Полусиблинг по матери",
            "Half-sibling on father's side": "Полусиблинг по отцу",
            "Hair with bulb": "Волосы с луковицей",
            "Histological specimen/paraffin block": "Гистологический образец / парафиновый блок",
            "Handkerchief or napkin with nasal mucus": "Платок или салфетка с носовой слизью",

            //I
            "Inlab Genetics": "Инлаб Генетикс",
            "Index": "Главная",
            "Indicators": "Показатели",
            /*ИНН*/
            "Individual taxpayer number": "Индивидуальный номер налогоплательщика",
            "Informational": "Информационный",
            "Institution employee": "Работник учреждения",
            "Informed consent": "Информированное согласие",
            "Invalid date.": "Неверная дата.",
            "Invalid email.": "Некорректная электронная почта.",
            "Image": "Изображение",
            "If you have a tracking number for the shipment with biological material for this order, please provide it.": "Если у Вас есть трек-номер отправления с биологическим материалом по данному заказу, укажите его.",
            "Info for a courier": "Информация для курьера",
            "Information about participants": "Сведения об участниках",

            //J
            "Job title": "Должность",

            //K
            "Knowledge base": "База знаний",
            "Kb": "Кб",
            "Kinship": "Родство",

            //L
            "Loading...": "Загрузка...",
            "Log in": "Войти",
            "Loading the user": "Загрузка пользователя",
            "Loading orders": "Загрузка заказов",
            "Legal status": "Юридический статус",
            "Loading ordered test types": "Загрузка заказанных видов теста",
            "Loading categories": "Загрузка категорий",
            "Last modification": "Последнее обновление",
            "Loading managers": "Загрузка менеджеров",
            "Limited Liability Company \"Company Name\"": "Общество с ограниченной ответственностью \"Название компании\"",
            "LLC \"Company Name\"": "ООО \"Название компании\"",
            "Legal address": "Юридический адрес",
            "Loading roles": "Загрузка ролей",
            "Loading test types": "Загрузка видов теста",
            "Loading the order": "Загрузка заказа",
            "Load more": "Загрузить еще",
            "Loading senior managers": "Загрузка старших менеджеров",
            "Loading partners": "Загрузка партнеров",
            "Loading available roles": "Загрузка доступных ролей",
            "Loading users": "Загрузка пользователей",
            "Liquid blood": "Кровь жидкая",
            "loaded": "загружено",
            "Loaded": "Загружено",
            "Loading applications": "Загрузка заявок",
            "Loading the application": "Загрузка заявки",
            "Last update": "Последнее обновление",
            "Login is required.": "Логин обязателен для заполнения.",
            "Legal address is required.": "Юридический адрес обязателен для заполнения.",
            "Legal status is required.": "Юридический статус обязателен для заполнения.",
            "Loading articles": "Загрузка статей",
            "Loading your personal manager": "Загрузка вашего персонального менеджера",
            "Loading the appeal": "Загрузка обращения",
            "Laboratory": "Лаборатория",
            "Loading appeals": "Загрузка обращений",
            "Loading tests": "Загрузка тестов",
            "Loading tests in trash count": "Загрузка количества тестов в корзине",
            "Legal statuses are loading": "Юридические статусы загружаются",
            "Loading partner types": "Загрузка партнерских типов",
            "Loading the module": "Загрузка модуля",

            //M
            "My Orders": "Мои заказы",
            "More": "Подробнее",
            "Mon": "Пн",
            "Message": "Сообщение",
            "Maximum size": "Максимальный размер",
            "Mb": "Мб",
            /*ОГРН*/
            "Main state registration number": "Основной государственный регистрационный номер",
            "Main": "Основное",
            "Maximum size of all files at once": "Максимальный размер всех файлов за раз",
            "Manager": "Менеджер",
            "mtDNA": "мтДНК",
            "Motherhood": "Материнство",
            "My Genes \"Light\"": "Мои Гены «Лайт»",
            "My Genes \"Figure\"": "Мои Гены «Фигура»",
            "My Genes \"Neurotype\"": "Мои Гены «Нейротип»",
            "My Genes \"Full Health\"": "Мои Гены «Полное здоровье»",
            "Maternal grandmother": "Бабушка по матери",
            "Mother's brother": "Брат матери",
            "Maternal grandfather": "Дедушка по матери",
            "Mother": "Мать",
            "Mother's sister": "Сестра матери",
            "Mugs and glasses": "Кружки и стаканчики",
            "maximum": "максимум",
            "Message subject": "Тема сообщения",
            "Message text": "Текст сообщения",
            "Maximum overall size": "Максимальный общий размер",
            "Minimum date: 01/01/1000.": "Минимальная дата: 01.01.1000.",
            "Main state registration number is required.": "ОГРН обязателен для заполнения.",
            "Main state registration number must be a number.": "ОГРН должен быть числом.",
            "Main state registration number consists of 13 digits.": "ОГРН состоит из 13 цифр.",
            "Main info": "Основная информация",
            "Mailing address": "Почтовый адрес",

            //N
            "Not chosen": "Не выбрано",
            "No": "Нет",
            "Name of category": "Название категории",
            "Name for the article": "Название статьи",
            "Name of the bank": "Наименование банка",
            "Note": "Примечание",
            "Needed": "Нужно",
            "Not needed": "Не нужно",
            "Number by order": "Номер по порядку",
            "Next": "Далее",
            "NIPT Veracity (3 pathologies)": "НИПТ Veracity (3 патологии)",
            "NIPT Veracity (8 pathologies)": "НИПТ Veracity (8 патологий)",
            "NIPT Veracity (12 pathologies)": "НИПТ Veracity (12 патологий)",
            "NIPT VeraGen (12 pathologies + 100 monogenic)": "НИПТ VeraGen (12 патологий + 100 моногенных)",
            "Nationality": "Национальность",
            "Nephew": "Племянник",
            "Niece": "Племянница",
            "Nails": "Ногти",
            "New data": "Новые данные",
            "New": "Новая",
            "Nested categories of articles": "Вложенные категории статей",
            "No search results": "Нет результатов поиска",
            "No physical addresses": "Нет физических адресов",
            "No tests": "Нет тестов",
            "No data": "Нет данных",
            "Name": "Имя",
            "New preview": "Новое превью",
            "No preview for this test": "Нет превью для этого теста",
            "Number in Bitrix": "Номер в Битриксе",
            "Number of the order in Bitrix": "Номер заказа в Битриксе",
            "Number of the document": "Номер документа",

            //O
            "Order number": "Номер заказа",
            "Open": "Открыть",
            "Open dialog": "Открыть диалог",
            "Old first": "Сначала старые",
            "Open the order": "Открыть заказ",
            "Order for DNA testing": "Заказ на ДНК-тестирование",
            "Other important information for the laboratory": "Другая важная для лаборатории информация",
            "Order creation date": "Дата создания заказа",
            "Other": "Другое",
            "Order ID": "ID заказа",
            "Order a DNA test": "Заказать ДНК-тест",
            "Origin of the family": "Происхождение рода",
            "Oral swab (buccal epithelium)": "Ротовой мазок (буккальный эпителий)",
            "Order the test": "Оформить тест",
            "Open the file": "Открыть файл",
            "Order ID from Bitrix": "ID заказа из Битрикса",
            "Order number in Bitrix": "Номер заказа в Битриксе",
            "Organization phone number": "Телефон организации",

            //P
            "Password": "Пароль",
            "Place a new order": "Оформить новый заказ",
            "Phone": "Телефон",
            "Parent category": "Родительская категория",
            "Preview for the article": "Превью для статьи",
            "Physical address": "Физический адрес",
            "Partner": "Партнер",
            "Partner ID": "ID партнера",
            "Partner documents attached to the order": "Документы партнера, прикрепленные к заказу",
            "Print the document": "Распечатать документ",
            "Previous order number": "Номер предыдущего заказа",
            "Participant ID": "ID участника",
            "Print the file": "Распечатать файл",
            "Partner loading": "Загрузка партнера",
            "Profile": "Профиль",
            "Paternity": "Отцовство",
            "Paternity+maternity": "Отцовство + материнство",
            "Prenatal fatherhood": "Дородовое отцовство",
            "Paternal grandmother": "Бабушка по отцу",
            "Paternal grandfather": "Дедушка по отцу",
            "Participant from an earlier test": "Участник из более раннего теста",
            "Previous test order number": "Номер заказа предыдущего теста",
            "Pacifier": "Соска",
            "Photo": "Фото",
            "Physical addresses": "Физические адреса",
            "Processed": "Обработана",
            "Partner name": "Название партнера",
            "Page not found": "Страница не найдена",
            "Partner info": "Информация о партнере",
            "Price": "Цена",
            "Physical addresses can't be empty": "Физические адреса не могут быть пустыми",
            "Print all barcodes": "Печать всех штрихкодов",
            "Print the barcode": "Печать штрихкода",
            "Phone number for the courier": "Номер телефона для курьера",
            "Partner type": "Тип партнера",
            "Pregnancy peculiarities are loading": "Особенности беременности загружаются",
            "Place an order on behalf of a partner": "Оформить заказ от имени партнера",
            "Provisional date of recording": "Предварительная дата записи",
            "Print the order form": "Печать бланка заказа",

            //Q

            //R
            "Requests for change of personal data": "Заявки на смену персональных данных",
            "Rows in table": "Строк в таблице",
            "Register a new partner": "Зарегистрировать нового партнера",
            /*КПП*/
            "Registration reason code": "Код причины постановки на учет",
            "Role": "Роль",
            "Register": "Зарегистрировать",
            "Return of samples": "Возврат образцов",
            "Result": "Результат",
            "Relation degree": "Степень родства",
            "Repeat password": "Повтор пароля",
            "Roles": "Роли",
            "Registration date": "Дата регистрации",
            "Razor": "Бритва",
            "Ready profile": "Готовый профиль",
            "Return of samples (only in case of remaining balance)": "Возврат образцов (только в случае остатка)",
            "Reject": "Отклонить",
            "Report an error or complain to a manager": "Сообщить об ошибке или пожаловаться руководителю",
            "Request for editing": "Заявка на редактирование",
            "Run import of tests from Bitrix": "Запустить импорт тестов из Битрикса",

            //S
            "Schedule": "График работы",
            "Sat": "Сб",
            "Sun": "Вс",
            "Status": "Статус",
            "StatusNew": "Новый",
            "StatusInProgress": "В работе",
            "StatusReady": "Готов",
            "StatusClosed": "Закрыто",
            "Select from the types of test you have previously ordered": "Выбор из заказанных вами ранее видов теста",
            "Sort by creation date": "Сортировка по дате создания",
            "Sort by presence of unread replies": "Сортировка по наличию НЕ прочитанных ответов",
            "Sort by alphabet": "Сортировка по алфавиту",
            "Status updating": "Обновление статуса",
            "Search by name": "Поиск по названию",
            "Search by article update date": "Поиск по дате обновления статьи",
            "Sort by name": "Сортировка по названию",
            "Sort by date updated": "Сортировка по дате обновления",
            "Save": "Сохранить",
            "Short meaning of the article": "Краткий смысл статьи",
            "Select a manager": "Выберите менеджера",
            "Short name of the legal entity": "Краткое наименование юридического лица",
            "Study period": "Срок исследования",
            "Sign": "Признак",
            "Select a file": "Выбрать файл",
            "show ID": "показать Id",
            "Sample type": "Вид образца",
            "Select roles": "Выберите роли",
            "Select photo": "Выбрать фото",
            "Select a senior manager": "Выберите старшего менеджера",
            "Select a new photo": "Выбрать новое фото",
            "Select which manager to transfer the partners of the deleted manager to": "Выберите к какому менеджеру перенести партнеров удаляемого менеджера",
            "Select which partner to transfer the orders of the removed partner to": "Выберите к какому партнеру перенести заказы удаляемого партнера",
            "Select which senior manager to transfer the managers of the removed senior manager to": "Выберите к какому старшему менеджеру перенести менеджеров удаляемого старшего менеджера",
            "Senior manager": "Старший менеджер",
            "Search by registration date": "Поиск по дате регистрации",
            "Search by email": "Поиск по электронной почте",
            "Search by full name or legal entity": "Поиск по ФИО или юридическому лицу",
            "Step 1: Select a test": "Шаг 1: Выбор теста",
            "Step 2: Specify Participants": "Шаг 2: Укажите участников",
            "Step 3: Advanced": "Шаг 3: Дополнительно",
            "Select test type": "Выберите вид теста",
            "Standard term according to price list": "Стандартный срок по прайс-листу",
            "Sample description": "Описание образца",
            "Soft tissue (parts of organs/muscles/skin)": "Мягкие ткани (части органов/мышцы/кожа)",
            "Stains on clothing or fabric": "Пятна на одежде или ткани",
            "Sperm on fabric / condom": "Сперма на ткани / презерватив",
            "Scanned copies of documents": "Скан-копии документов",
            "Send": "Отправить",
            "Supreme administrator": "Верховный администратор",
            "Short name is required.": "Краткое наименование обязательно для заполнения.",
            "Select a role": "Выберите роль",
            "Show categories in trash can": "Показывать категории в корзине",
            "Select a category": "Выберите категорию",
            "Search": "Поиск",
            "Search results": "Результаты поиска",
            "Send with corrections": "Отправить с исправлениями",
            "Search articles": "Поиск статей",
            "Subject": "Тема",
            "Search by text": "Поиск по тексту",
            "Search by subject": "Поиск по теме",
            "Sorting by subject": "Сортировка по теме",
            "Search by title": "Поиск по названию",
            "Serial number": "Порядковый номер",
            "Short description": "Краткое описание",
            "Show processed appeals too": "Показывать также обработанные заявки",
            "Select date": "Выберите дату",
            "Select time of the day": "Выберите время дня",
            "Sample types are loading": "Виды образцов загружаются",
            "Select a preview for this test": "Выберите превью для этого теста",
            "Study periods are loading": "Сроки исследования загружаются",
            "Select the partner type": "Выберите тип партнера",
            "Something went wrong. Error code:": "Что-то пошло не так. Код ошибки:",
            "Select the partner": "Выберите партнера",
            "Search by creation date": "Поиск по дате создания",
            "Search by order number": "Поиск по номеру заказа",
            "Search by participant's name": "Поиск по имени участника",
            "Search by a number in Bitrix": "Поиск по номеру в Битриксе",
            "Search by a number of the order in Bitrix": "Поиск по номеру заказа в Битриксе",
            "Select a partner": "Выберите партнера",
            "Search by order id": "Поиск по номеру заказа",

            //T
            "Tue": "Вт",
            "Thu": "Чт",
            "Type of test": "Вид теста",
            "Test participants": "Участники теста",
            "There are no orders.": "Нет ни одного заказа.",
            "Test": "Тест",
            "This action cannot be undone": "Это действие нельзя будет отменить",
            "The trash can has been emptied": "Корзина очищена",
            "The category was successfully saved": "Категория успешно сохранена",
            "The category has been successfully updated": "Категория успешно обновлена",
            "There are no articles in the selected category": "Нет статей в выбранной категории",
            "There are no available categories": "Нет доступных категорий",
            "The preview is updated as soon as you select a new picture, i.e. the preview will be updated even if you don't click \"Save\".": "Превью обновляется как только вы выберете новую картинку, т.е. превью будет обновлено даже если вы не нажмете \"Сохранить\".",
            "There is not a single physical address, you must add at least one.": "Нет ни одного физического адреса, надо добавить хотя бы один.",
            "The partner has been successfully registered. Soon, the partner will receive a letter, on email address, chosen for login with the credentials for enter.": "Партнер успешно зарегистрирован. Скоро партнер получит письмо, на электронный адрес, выбранный для входа, с логином и паролем для входа.",
            "Track number of sent samples": "Трек-номер отправленных образцов",
            "Translation of the conclusion into a foreign language": "Перевод заключения на иностранный язык",
            "Test other": "Тест другое",
            "There are no files.": "Файлы отсутствуют.",
            "The essence of the document": "Суть документа",
            "The essence of the document is not selected": "Суть документа не выбрана",
            "The user was successfully saved.": "Пользователь успешно сохранен.",
            "The user has been successfully updated.": "Пользователь успешно обновлен.",
            "The user was successfully deleted.": "Пользователь успешно удален.",
            "Transfer to another partner": "Перенести к другому партнеру",
            "To": "До",
            "The partner has been successfully updated.": "Партнер успешно обновлен.",
            "Treason test": "Тест на измену",
            "Toothbrush": "Зубная щетка",
            "Toothpicks": "Зубочистки",
            "Teeth": "Зубы",
            "total size up to": "общим размером до",
            "The application has already been processed.": "Заявка уже обработана.",
            "The decision was successfully made.": "Решение успешно принято.",
            "The order for DNA testing has been successfully created.": "Заказ на выполнение ДНК-тестирования успешно создан.",
            "The password must not be shorter than 6 characters.": "Пароль не должен быть короче 6 символов.",
            "The password is required.": "Пароль обязателен для заполнения.",
            "The files were successfully uploaded": "Файлы были успешно загружены",
            "TIN must be a number.": "ИНН должен быть числом.",
            "The TIN consists of 10 digits.": "ИНН состоит из 10 цифр.",
            "TIN is required to be filled out.": "ИНН обязателен для заполнения.",
            "The registration reason code must be a number.": "КПП должен быть числом.",
            "The registration reason code consists of 9 digits.": "КПП состоит из 9 цифр.",
            "The registration reason code is required to be filled out.": "КПП обязателен для заполнения.",
            "The checking account must be a number.": "Расчетный счет должен быть числом.",
            "The checking account consists of 20 digits.": "Расчетный счет состоит из 20 цифр.",
            "The checking account is required to be filled out.": "Расчетный счет обязателен для заполнения.",
            "The correspondent account must be a number.": "Корреспондентский счет должен быть числом.",
            "The correspondent account consists of 20 digits.": "Корреспондентский счет состоит из 20 цифр.",
            "The correspondent account starts with 301.": "Корреспондентский счет начинается с 301.",
            "The correspondent account is required to be filled out.": "Корреспондентский счет обязателен для заполнения.",
            "The name of the bank is required to be filled out.": "Наименование банка обязательно для заполнения.",
            "The sign is required to be filled out.": "Признак обязателен для заполнения.",
            "The test type is required.": "Вид теста обязателен для заполнения.",
            "The previous order number is required.": "Номер предыдущего заказа обязателен для заполнения.",
            "The study period is required to be filled out.": "Срок исследования обязателен для заполнения.",
            "The description of the sample is required to be filled out.": "Описание образца обязательно для заполнения.",
            "The sample type is required.": "Вид образца обязателен для заполнения.",
            "The test description is required.": "Описание теста обязательно для заполнения.",
            "The field \"Who collected the samples\" is required.": "Поле \"Кто собрал образцы\" обязательно для заполнения.",
            "The article was successfully saved.": "Статья успешно сохранена.",
            "The phone cannot be empty.": "Телефон не может быть пустым.",
            "The physical address cannot be empty.": "Физический адрес не может быть пустым.",
            "There are no nested categories.": "Нет вложенных категорий.",
            "The password must not be shorter than some characters.": "Пароль не должен быть короче {{count}} символов.",
            "There are unreliable data": "Есть недостоверные данные",
            "Type a new value": "Впишите новое значение",
            "There are no contacts": "Нет контактов",
            "The request performed successfully.": "Запрос успешно выполнен.",
            "Text": "Текст",
            "There are no appeals": "Нет обращений",
            "Title": "Название",
            "The new test has been successfully created": "Новый тест был успешно создан",
            "The test has been successfully updated": "Тест был успешно обновлен",
            "The test in trash": "Тест в корзине",
            "Tests in trash": "Тесты в корзине",
            "Type a complete email": "Напишите email полностью",
            "The courier was successfully called. Expect.": "Курьер успешно вызван. Ожидайте.",
            "The opened order": "Открытый заказ",
            "The test data isn't loaded": "Данные по тесту не загрузились",
            "This appeal is closed.": "Это обращение закрыто.",
            "The order is in trash": "Заказ находится в корзине",
            "The order is loading": "Заказ загружается",
            "The barcodes are fetching": "Штрихкоды загружаются",
            "The address where the courier should arrive": "Адрес куда должен приехать курьер",
            "Type of partner": "Тип партнера",
            "This field is required": "Это поле обязательно для заполнения",
            "Time zone": "Часовой пояс",
            "The participant editing/adding form is not empty. Please add/edit the participant or clear the form.": "Форма редактирования/добавления участников не пуста. Пожалуйста добавьте/отредактируйте участника или очистите форму.",
            "Type": "Тип",
            "Type of the provided document": "Тип предоставленного документа",

            //U
            "Users": "Пользователи",
            "User ID": "ID пользователя",
            "Urgent delivery of the report by courier": "Срочная отправка заключения курьером",
            "Upload": "Загрузить",
            "Uploading documents": "Загрузка документов",
            "Upload files": "Загрузить файлы",
            "Uploaded documents": "Загруженные документы",
            "Update": "Обновить",
            "Until the court's decision": "До решения суда",
            "Unauthorized user": "Неавторизованный пользователь",
            "Updated": "Обновлено",
            "Unfortunately, an error occurred while loading partner types.": "К сожалению при загрузке типов партнеров произошла ошибка.",
            "Unfortunately, an error occurred while loading managers.": "К сожалению при загрузке менеджеров произошла ошибка.",
            "Unfortunately, an error occurred while loading roles for a contact.": "К сожалению при загрузке ролей для контакта произошла ошибка.",
            "Unfortunately, roles weren't loaded.": "К сожалению роли не были загружены.",

            //V
            "Valid extensions": "Допустимые расширения",
            "valid formats": "допустимые форматы",
            "Value": "Значение",
            "View application": "Просмотр заявки",

            //W
            "Wed": "Ср",
            "Written consultation with a test specialist": "Письменная консультация специалиста по тесту",
            "Who collected the samples": "Кто собрал образцы",
            "What to do with existing orders from this partner?": "Что делать с существующими заказами данного партнера?",
            "Watch strap": "Ремешок от часов",
            "Write a message": "Напишите сообщение",
            "Who collected samples variants are loading": "Варианты кто собрал образцы загружаются",
            "When the courier will arrive, time from": "Когда приедет курьер, время от",
            "When the courier will arrive, time to": "Когда приедет курьер, время до",

            //X
            "X chromosome": "X-хромосома",

            //Y
            "You have successfully logged in. You can go to the section you need.": "Вы успешно вошли. Можете перейти в нужный вам раздел.",
            "You've successfully exited.": "Вы успешно вышли.",
            "Your manager": "Ваш менеджер",
            "Yes": "Да",
            "You can download up to": "За раз можно загрузить до",
            "Your profile": "Ваш профиль",
            "Your account roles": "Роли вашего аккаунта",
            "You cannot add more participants.": "Больше участников добавить нельзя.",
            "Y chromosome": "Y-хромосома",
            "You can upload": "Вы можете загрузить",
            "You have uploaded the maximum number of files.": "Вы загрузили максимальное количество файлов.",
            "You have exceeded the maximum files upload size.": "Вы превысили максимальный размер загружаемых файлов.",
            "You do not have access to this section.": "У вас нет доступа в этот раздел.",
            "You are making too many requests.": "Вы делаете слишком много запросов.",
            "You cannot add more than 5 participants.": "Нельзя добавить больше 5 участников.",
            "You": "Вы",
            "You need to choose the type of partner.": "Вам нужно выбрать тип партнера",

            //Z


            "1 day(24 hours)": "1 день (24 часа)",
            "2 days(48 hours)": "2 дня (48 часов)",
        }
    }
};

const defaultLang = "ru";
const storageLangKey = "inlabB2BUserLang";

let langFromStorage;
try {
    langFromStorage = localStorage.getItem(storageLangKey);
} catch (e: unknown) {
    langFromStorage = defaultLang;
}

if (langFromStorage === null) {
    localStorage.setItem(storageLangKey, defaultLang);
    initI18N({lang: defaultLang});
} else {
    initI18N({lang: langFromStorage});
}

export function getLangFromStorage() {
    const langFromStorage = localStorage.getItem(storageLangKey);
    if (langFromStorage === null) {
        return defaultLang;
    } else {
        return langFromStorage;
    }
}


function initI18N({lang}: {lang: string}) {
    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
}

export function setUserLangToStorage({lang}: {lang: string}) {
    localStorage.setItem(storageLangKey, lang);
}

export default i18n;