import { NewBaseResponseInterface } from "@/common/Http/Interfaces/NewBaseResponseInterface";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import { ApiSlice } from "@/store/ApiSlice";

const restApiConfig = new RESTAPIConfig();

export const PrintedOrderSheetApiSlice = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        printedOrderSheet: builder.query<NewBaseResponseInterface<unknown>, {id: number}>({
            query: ({id}: {id: number}) => ({
                url: restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.orderSheetPrinted({orderId: id}),
            }),
        })
    })
});

export const {useLazyPrintedOrderSheetQuery} = PrintedOrderSheetApiSlice;