import { NewBaseResponseInterface } from "@/common/Http/Interfaces/NewBaseResponseInterface";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import { ApiSlice } from "@/store/ApiSlice";
import { OpenedOrderInterface } from "./Interfaces/OpenedOrderInterface";

const restApiConfig = new RESTAPIConfig();

export const OpenedOrderApiSlice = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        getOrder: builder.query<NewBaseResponseInterface<OpenedOrderInterface>, {id: number}>({
            query: ({id}: {id: number}) => ({
                url: restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getDNAOrderData({orderId: id}),
            }),
            providesTags: (result, error, arg) => [{ type: 'MyOrders', id: arg.id }, "FetchedData"],
        })
    })
});

export const {useGetOrderQuery} = OpenedOrderApiSlice;