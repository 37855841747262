import { NewBaseResponseInterface } from "@/common/Http/Interfaces/NewBaseResponseInterface";
import RESTAPIConfig from "@/config/RESTAPIConfig";
import { ApiSlice } from "@/store/ApiSlice";
import { BarcodesDataInterface } from "./Interfaces/BarcodesDataInterface";

const restApiConfig = new RESTAPIConfig();

export const BarcodeApiSlice = ApiSlice.injectEndpoints({
    endpoints: builder => ({
        getBarcodes: builder.query<NewBaseResponseInterface<BarcodesDataInterface>, {id: number}>({
            query: ({id}: {id: number}) => ({
                url: restApiConfig.baseUrl + restApiConfig.apiPrefix + restApiConfig.getBarcodesForOrder({orderId: id}),
            }),
        })
    })
});

export const {useGetBarcodesQuery} = BarcodeApiSlice;