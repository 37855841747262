import { useGetOrderQuery } from "@/api/Orders/OpenedOrder/OpenedOrderApiSlice";
import styles from "./styles/PrintOrderForm.module.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CircularLoading } from "@/components/Loading/CircularLoading/CircularLoading";
import { useGetBarcodesQuery } from "@/api/Orders/Barcode/BarcodeApiSlice";
import { Paper } from "@mui/material";
import Section from "./Section";
import { ParticipantInterface } from "@/api/Orders/OpenedOrder/Interfaces/ParticipantInterface";
import { useLazyPrintedOrderSheetQuery } from "@/api/Orders/PrintedOrderSheet/PrintedOrderSheetApiSlice";
import { useEffect } from "react";

function PrintOrderForm() {

    const { t } = useTranslation();
    const orderNum = Number(useParams().orderNum);

    const {
        data: orderData,
        isFetching: orderIsFetching,
        isSuccess: orderIsSuccess,
    } = useGetOrderQuery({ id: orderNum });
    const order = orderData !== undefined ? orderData.data : null;

    const {
        data: barcodesData,
        isFetching: barcodesFetching,
        isSuccess: barcodesSuccess,
        error: barcodesError,
    } = useGetBarcodesQuery({ id: orderNum });
    const barcodes = barcodesData !== undefined ? barcodesData.data.barcodes : [];

    let anotherImportantInfo = "---";
    if (order !== null && order.anotherImportantInfo !== null && order.anotherImportantInfo.trim() !== "") {
        anotherImportantInfo = order.anotherImportantInfo;
    }

    const [sheetPrintedTrigger] = useLazyPrintedOrderSheetQuery();

    useEffect(() => {
        async function handleAfterPrint() {
            try {
                await sheetPrintedTrigger({ id: orderNum });
            } catch (e: any) { }
        }

        window.addEventListener("afterprint", handleAfterPrint);

        return () => {
            window.removeEventListener("afterprint", handleAfterPrint);
        };
    }, []);

    return (
        <div className={styles.wrap}>

            <h3 className={styles.pageHeader}>{t("Order number")}: {orderNum}</h3>

            {orderIsFetching && <CircularLoading text={`${t("Loading the order")}...`} />}
            {barcodesFetching && <CircularLoading text={`${t("The barcodes are fetching")}...`} />}

            {orderIsSuccess && barcodesSuccess && order !== null &&
                <>
                    <Section header={t("Main")}>
                        <div>
                            {t("Test")}: {order.analysis !== null && order.analysis.title}
                        </div>
                        <div>
                            {t("Test other")}:{order.testTypeOther ?? "---"}
                        </div>
                        <div>
                            {t("Organization phone number")}: {order.user.partner.company_phone === null ? "---" : order.user.partner.company_phone}
                        </div>
                    </Section>

                    <Section header={t("End customer")}>
                        <div>
                            {t("Name")}: {order.end_customer_of_the_service.name.trim() !== "" ? order.end_customer_of_the_service.name : "---"}
                        </div>
                        <div>
                            {t("Phone")}: {order.end_customer_of_the_service.phone.trim() !== "" ? order.end_customer_of_the_service.phone : "---"}
                        </div>
                    </Section>

                    <Section header={t("For laboratory marks")}>
                        {/* <div>{t("For laboratory marks")}:</div> */}
                        <Paper variant="outlined">
                            <br />
                            <br />
                        </Paper>
                    </Section>

                    <Section header={t("Other important information for the laboratory")}>
                        {anotherImportantInfo}
                    </Section>

                    <Section header={t("Comments on all samples")}>
                        {order.comment_samples ?? "---"}
                    </Section>

                    <Section header={t("Information about participants")}>
                        {barcodes.map((code, index) => {

                            let fioOrNickname = "---";
                            let sampleType = "---";
                            let sampleTypeOther = "---";
                            let date_sample = "---";
                            let gender = "---";
                            let document_type = "---";
                            let document_number = "---";

                            if (order !== null) {
                                const participant = order.participants[index] as ParticipantInterface | void;

                                if (participant !== undefined) {
                                    if (participant.fioOrNickname.trim() !== "") {
                                        fioOrNickname = participant.fioOrNickname;
                                    }
                                    if (participant.sample.title.trim() !== "") {
                                        sampleType = participant.sample.title;
                                    }
                                    if (participant.sampleTypeOther !== null && participant.sampleTypeOther.trim() !== "") {
                                        sampleTypeOther = participant.sampleTypeOther;
                                    }
                                    if (participant.date_sample !== null && participant.date_sample.trim() !== "") {
                                        date_sample = participant.date_sample as string;
                                    }
                                    if (participant.gender !== null && participant.gender.trim() !== "") {
                                        gender = participant.gender as string;
                                    }
                                    if (participant.document_type !== null && participant.document_type.trim() !== "") {
                                        document_type = participant.document_type as string;
                                    }
                                    if (participant.document_number !== null && participant.document_number.trim() !== "") {
                                        document_number = participant.document_number as string;
                                    }
                                }
                            }

                            return <div key={index}>
                                {order !== null &&
                                    <>
                                        {/* 
                
                                        <div>
                                            {t("Study period")}: {order.order_term.title}
                                        </div>
                                        <div>
                                            {t("Order ID from Bitrix")}: {order.bitrix_order_id ?? "---"}
                                        </div>
                                        
                                        <div>
                                            {t("Sample type")}: {sampleType}
                                        </div> */}
                                        <div>
                                            <span className={styles.participantNum}>{index + 1})</span> {t("Full name or nickname")}: {fioOrNickname}
                                        </div>


                                        <div>
                                            {t("Date of sample collection")}: {date_sample}
                                        </div>
                                        <div>
                                            {t("Gender of the participant")}: {gender}
                                        </div>
                                        <div>
                                            {t("Type of the provided document")}: {document_type}
                                            <br />
                                            {t("Number of the document")}: {document_number}
                                        </div>

                                        <div>
                                            {t("For laboratory marks for the sample")}:
                                            <Paper sx={{ mt: 1, mb: 1, }} variant="outlined">
                                                <br />
                                                <br />
                                            </Paper>
                                        </div>

                                        {/* <div>
                                            {t("Description of another type of sample")}: {sampleTypeOther}
                                        </div> */}
                                    </>
                                }

                                <img className={styles.barcodeImg} key={code} src={`data:image/png;base64,${code}`} alt="" />
                            </div>
                        })}
                    </Section>

                </>
            }

        </div>
    );
}

export default PrintOrderForm;